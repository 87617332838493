/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
// Boolean indicating whether this is an article:
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Trans} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
const MDXLayout = Layout;
import Seo from "../components/Seo";
export const Head = props => React.createElement(Seo, {
  title: props.pageContext.frontmatter?.title,
  description: props.pageContext.frontmatter?.description,
  image: props.pageContext.frontmatter?.image,
  article: true
});
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, React.createElement(Trans, null, "Contactez les Laboratoires Pichot")), "\n", React.createElement(Trans, null, "Notre équipe commerciale est composée de 3 expert(e)s en packaging, qui vous guideront dans votre projet de développement produit."), "\n", React.createElement(Trans, null, "Avec plus de 30 ans d'expériences dans l'accompagnement de nos clients dans la création de produit et de gammes pertinentes pour les compléments alimentaires, ..., notre valeur ajoutée est de vous conseiller le bon flacon, la bonne matière, le bon bouchage, adapté à votre besoin et en maximisant l'écoresponsabilité de votre produit."), "\n", React.createElement(_components.p, null, "Téléphone: 04 73 72 63 06\r\nemail: contact@labopichot.com"), "\n", React.createElement("script", {
    type: "application/ld+json"
  }, `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://flaconpet.com",
      "name": "Laboratoires Pichot",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+33-473-726-606",
        "contactType": "Customer Support"
      }
    }
  `));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
